import { Button, Form, Modal, Popconfirm, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import BasicCrudAddForm from './BasicCrudAddForm'
import { type LogInstance } from '../../DashboardUtils'
import { selectAllFrom } from '../../supabaseClient'

interface BasicCrudTableProps<T> {
  tableName: string
  elementName: string
  propColumns: Array<BasicCrudColumn<T>>
  deleteKey: keyof T
  additionalDispatchersToAnnounce: Array<React.Dispatch<React.SetStateAction<number>>>
  dashboardMessage: LogInstance
}

const BasicCrudTable = <T extends Record<string, any>> ({
  tableName,
  elementName,
  propColumns,
  deleteKey,
  additionalDispatchersToAnnounce,
  dashboardMessage
}: BasicCrudTableProps<T>): React.JSX.Element => {
  type DataTypeWithKey = T & { key: any }
  const [form] = Form.useForm()
  const [data, setData] = useState<DataTypeWithKey[]>([])
  const [open, setOpen] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (tableName == null) console.error(`tableName was empty for ${elementName}`)
        const { data, error } = await selectAllFrom<T>(tableName)
        if (error != null) {
          dashboardMessage.error(error.message)
        }
        if (Array.isArray(data)) {
          if (data[0][deleteKey] == null) dashboardMessage.error(`deleteKey values are null for ${tableName} BasicCrudTable`)
          const bodyWithKey = data.map((x: any) => ({
            ...x,
            key: x[deleteKey]
          }))
          setData(bodyWithKey)
        }
      } catch (error: any) {
        dashboardMessage.error(error)
      }
    }

    fetchData().catch(dashboardMessage.error)
  }, [refreshKey])

  // const handleDelete = async (key: string): Promise<void> => {
  //   try {
  //     const response = await fetch(`${endpoint}/${encodeURI(key)}`, {
  //       method: 'DELETE'
  //     })

  //     if (response.ok) {
  //       console.log(`${elementName} deleted successfully`)
  //       // Announce that an item was deleted
  //       announceChangeState()
  //     } else {
  //       dashboardMessage.error(`Failed to delete ${elementName}: ${response.statusText}`)
  //     }
  //   } catch (error) {
  //     dashboardMessage.error(`Failed to delete ${elementName}`, error)
  //   }
  // }

  const showModal = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    form.resetFields()
  }

  const handleOk = (): void => {
    form.submit()
    setOpen(false)
  }

  const announceChangeState = (): void => {
    console.log(`Announcing change state from ${tableName}`)
    setRefreshKey((refreshKey) => refreshKey + 1)
    // Announce the update to additional listeners, defined via prop
    additionalDispatchersToAnnounce.forEach(
      (setter) => {
        if (typeof setter === 'function') {
          setter((prevState: number) => prevState + 1)
        }
      }
    )
  }

  const columns = [
    ...propColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (_: any, _record: T) => (
        <Popconfirm
          title="Are you sure?"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          // onConfirm={async () => { await handleDelete(record.key) }}
        >
          <Button title="Delete" danger>Delete</Button>
        </Popconfirm>
      )
    }
  ]

  const footer = (): React.JSX.Element => (
    <Button type="primary" onClick={showModal} disabled={true}>
      Add {elementName}
    </Button>
  )
  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        scroll={{ y: 'calc(100vh - 616px)', x: '300px' }}
        pagination={false}
        footer={footer}
      />
      <Modal
        title={'Add ' + elementName}
        open={open}
        onCancel={handleClose}
        onOk={handleOk}
      >
        <BasicCrudAddForm
          form={form}
          itemAddedHandler={announceChangeState}
          tableName={tableName}
          columns={propColumns}
          dashboardMessage={dashboardMessage}
        />
      </Modal>
    </>
  )
}

export default BasicCrudTable
