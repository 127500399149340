import React, { useEffect, useState } from 'react'
import BasicCrudTable from './BasicCrudTable'
import { Input, Select } from 'antd'
import { type LogInstance } from '../../DashboardUtils'
import { selectAllFrom } from '../../supabaseClient'

const KindredConfigPaneItemAliases: React.FunctionComponent<{
  additionalDispatchersToAnnounce: Array<React.Dispatch<React.SetStateAction<number>>>
  refreshOptionsTrigger: any
  dashboardMessage: LogInstance
}> = ({ additionalDispatchersToAnnounce, refreshOptionsTrigger, dashboardMessage }) => {
  const [options, setOptions] = useState<Option[]>([])

  const fetchOptions = async (): Promise<void> => {
    try {
      const { data, error } = await selectAllFrom<RecognizedItemResponse>('kindred_recognized_item')
      if (error != null) console.error(error)
      else if (data == null) console.error('data was null for KindredRecognizedItem call')
      else {
        setOptions(
          data.map((recognizedItem: RecognizedItemResponse) => ({
            value: recognizedItem.item_name,
            label: recognizedItem.item_name
          }))
        )
      }
    } catch (error) {
      console.error('Failed to fetch options', error)
    }
  }

  useEffect(() => {
    fetchOptions().catch(console.error)
  }, [refreshOptionsTrigger])

  const propColumns: Array<BasicCrudColumn<ItemAliasResponse>> = [
    {
      title: 'Uncleansed name',
      dataIndex: 'uncleansed_name',
      key: 'uncleansedName',
      input: <Input />
    },
    {
      title: 'Actual name',
      dataIndex: 'item_name',
      key: 'itemName',
      input: <Select options={options} />
    }
  ]

  return (<>
    <BasicCrudTable<ItemAliasResponse>
      tableName="kindred_item_alias"
      elementName="item alias"
      propColumns={propColumns}
      deleteKey="uncleansed_name"
      additionalDispatchersToAnnounce={additionalDispatchersToAnnounce}
      dashboardMessage={dashboardMessage}
    /></>
  )
}

export default KindredConfigPaneItemAliases
