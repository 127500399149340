import { createClient, type PostgrestError, type PostgrestResponse } from '@supabase/supabase-js'

const supabaseUrl: string = (process.env.REACT_APP_SUPABASE_URL as string)
const supabaseAnonKey: string = (process.env.REACT_APP_SUPABASE_ANON_KEY as string)

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export async function selectAllFrom<T> (tableName: string): Promise<{
  data: T[] | null
  error: PostgrestError | null
}> {
  const response: PostgrestResponse<T> = await supabase.from(tableName).select('*')
  const { data, error } = response
  return { data, error }
}
