import React, { useEffect, useMemo, useState } from 'react'
import './App.css'
import Home from './Dashboard'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { supabase } from './supabaseClient'
import { Layout, Menu } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import KindredDashboard from './Kindred/Dashboards/KindredDashboard'
import { type Session } from '@supabase/supabase-js'
import Auth from './Auth'
import { PermissionContext } from './PermissionContext'

const App: React.FunctionComponent = () => {
  const [session, setSession] = useState<Session | null>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const value = useMemo(() => ({ isAdmin, setAdmin: setIsAdmin }), [isAdmin])
  const authenticated = session !== null

  function signOut (event: React.MouseEvent<HTMLLabelElement> | React.KeyboardEvent<HTMLLabelElement>): void {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return
    }
    supabase.auth.signOut().then(console.log).catch(console.error)
  }

  const items = [
    {
      key: '/',
      label: authenticated ? <Link to="/" style={{ textDecoration: 'none' }}>Home</Link> : <span>Home</span>
    },
    {
      key: '/kindred',
      label: authenticated
        ? <Link to="/kindred" style={{ textDecoration: 'none' }}>Kindred</Link>
        : <span>Kindred</span>
    }
  ]

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      if (session?.user?.app_metadata?.userrole === 'admin') {
        setIsAdmin(true)
      }
    }).catch(console.error)

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      if (session?.user?.app_metadata?.userrole === 'admin') {
        setIsAdmin(true)
      }
    })
  }, [])

  return (
    <PermissionContext.Provider value={value}>
      <Layout style={{ height: '100vh', overflow: 'scroll', overflowX: 'hidden', backgroundColor: '#ffffff' }}>
        <Header className="header" style={{ boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.2)' }}>
          <div style={{ float: 'left', marginRight: '20px', marginLeft: '20px' }}><label className="logo"
                                                                                         onClick={signOut}
                                                                                         onKeyDown={signOut}>Lanny</label>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={[useLocation().pathname]}
            style={{ borderBottom: 'none' }}
            items={items}
            disabled={!authenticated}
          />
        </Header>
        <Content>
          {authenticated
            ? <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/kindred" element={<KindredDashboard/>}/>
            </Routes>
            : <Auth/>
          }

        </Content>
      </Layout>
    </PermissionContext.Provider>
  )
}

export default App
