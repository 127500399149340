import React from 'react'
import BasicCrudTable from './BasicCrudTable'
import { Input } from 'antd'
import { type LogInstance } from '../../DashboardUtils'

interface KindredEnemy {
  enemy_name: string
}

const KindredConfigPaneEnemy: React.FunctionComponent<{ dashboardMessage: LogInstance }> = ({ dashboardMessage }) => {
  const columns: Array<BasicCrudColumn<KindredEnemy>> = [
    {
      title: 'Enemy name',
      dataIndex: 'enemy_name',
      key: 'enemyName',
      input: <Input/>
    }
  ]

  return (<>
    <BasicCrudTable<KindredEnemy>
      tableName="kindred_enemy"
      elementName="enemy"
      propColumns={columns}
      deleteKey={'enemy_name'}
      additionalDispatchersToAnnounce={[]}
      dashboardMessage={dashboardMessage}/>
      </>
  )
}

export default KindredConfigPaneEnemy
