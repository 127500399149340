import { Card, Descriptions } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { supabase } from '../../supabaseClient'

interface KindredInfo {
  location?: string
  lastBattle?: string
}

const KindredCardInfo: React.FunctionComponent = () => {
  const [info, setInfo] = useState<KindredInfo>({})

  function processInfo (data: any): void {
    if (data.data !== undefined) {
      setInfo(data.data)
    }
  }

  function fetchInfo (): void {
    void supabase
      .rpc('kindred_info')
      .then(processInfo, (err) => { console.error(err) })
  }

  useEffect(() => {
    fetchInfo()
    const interval = setInterval(() => {
      fetchInfo()
    }, 1000 * 60)
    return () => { clearInterval(interval) }
  }, [])

  if (info == null || info.location == null || info.lastBattle == null) {
    // Handle the case when one or more values are missing.
    return <Card title="Information" loading={true} style={{ width: '260px', marginLeft: '16px', marginRight: '16px', marginTop: '16px', marginBottom: '16px' }}/>
  }

  return (
    <Card title="Information" style={{ maxWidth: '260px', marginLeft: 16, marginRight: 16, marginTop: 16, marginBottom: 16 }}>
      <Descriptions size="small" column={1} contentStyle={{ wordBreak: 'normal' }} style={{ minWidth: 196 }}>
        <Descriptions.Item label="Location">{info.location}</Descriptions.Item>
        <Descriptions.Item label="Last battle">{moment(info.lastBattle).fromNow()}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default KindredCardInfo
