import { Badge, Card, Col, Descriptions, Dropdown, type MenuProps, Row, Space } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { supabase } from '../../supabaseClient'
import { PermissionContext } from '../../PermissionContext'

interface AdditionalInfo {
  lastBattle: string
  kindredLocation: string
}

const moreThanMinutesAgo = (timestamp: string, minutes: number): boolean => {
  const currentTime = Date.now()
  const targetTime = new Date(timestamp).getTime() + minutes * 60 * 1000

  return currentTime > targetTime
}

const workingDescription = <Descriptions.Item key="status" label="Status"><Space><Badge
  status="success"/>working</Space></Descriptions.Item>
const battleMomentsAgo = (lastBattle: string): React.JSX.Element => {
  const status = moreThanMinutesAgo(lastBattle, 3) ? 'error' : 'warning'
  return <Descriptions.Item key="latestEvent" label="Latest battle"
                            labelStyle={{ justifyContent: 'space-evenly' }}><Space><Badge
    status={status}/>{moment(new Date(lastBattle)).fromNow()}</Space></Descriptions.Item>
}

const items: MenuProps['items'] = [
  {
    key: 'kindred_wilds',
    label: 'Kindred Wilds'
  },
  {
    key: 'heph_peak',
    label: 'Hephaestus Peak'
  }
]

const onClick: MenuProps['onClick'] = ({ key }) => {
  void supabase
    .rpc('private', { p_field_name: 'kindred_config_location', p_value: key }).then(console.log)
}

const SummaryAdditionalInfo: React.FunctionComponent<AdditionalInfo> = ({
  lastBattle,
  kindredLocation
}): React.JSX.Element => {
  const { isAdmin } = useContext(PermissionContext)
  const [key, setKey] = useState<string>()

  const updateKey = (kindredLocation: string): void => {
    const locationKey = items.find((element) => {
      return element != null && 'label' in element && element.label === kindredLocation
    })?.key?.toString() ?? 'kindred_wilds'
    setKey(locationKey)
  }

  useEffect(() => {
    updateKey(kindredLocation)
  }, [kindredLocation])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function locationDescription (location: string) {
    // eslint-disable-next-line no-constant-condition
    let innerContents

    function preventDefault (event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>): void {
      event.preventDefault()
    }

    if (isAdmin) {
      innerContents =
        <Dropdown menu={{
          items,
          onClick,
          defaultSelectedKeys: [key ?? 'kindred_wilds'],
          selectedKeys: [key ?? 'kindred_wilds']
        }}>
          <a onClick={preventDefault} onKeyDown={preventDefault}>
            <Space>
              {location}
            </Space>
          </a>
        </Dropdown>
    } else {
      innerContents = location
    }
    return <Descriptions.Item key="location" label="Location">
      <Space><Badge status="default"/>
        {innerContents}
      </Space>
    </Descriptions.Item>
  }

  const infoDetails: React.JSX.Element[] = []
  infoDetails.push(locationDescription(kindredLocation))

  if (moreThanMinutesAgo(lastBattle, 2)) {
    infoDetails.push(battleMomentsAgo(lastBattle))
  }

  if (infoDetails.length === 1) {
    infoDetails.push(workingDescription)
  }

  return <Card title="Details" bordered={false} headStyle={{ textAlign: 'center' }}
               style={{ flex: 'auto', width: 'min-content', margin: 1 }}>
    <Row justify="center" style={{ minWidth: 222 }}>
      <Col span={24} style={{ maxWidth: '200px', minInlineSize: 'fit-content' }}>
        <Descriptions colon={false} column={1} size="middle" style={{ minWidth: 217 }}
                      labelStyle={{ fontSize: 16, paddingTop: '12px', textAlign: 'center' }}
                      contentStyle={{
                        fontSize: 16,
                        paddingTop: '12px',
                        textAlign: 'center',
                        justifyContent: 'right',
                        wordBreak: 'normal'
                      }}>
          {infoDetails}
        </Descriptions>
      </Col>
    </Row>
  </Card>
}

export default SummaryAdditionalInfo
