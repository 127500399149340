import React from 'react'
import KindredDropsListTable from '../Others/KindredDropsListTable'
import { Card, Tabs } from 'antd'

const KindredDropsListCard: React.FunctionComponent<{
  externalRefreshTableKey: number
  refetchRecognizedItemsTrigger: number
  additionalRefreshTriggerSetters: Array<React.Dispatch<React.SetStateAction<number>>>
}> = ({ externalRefreshTableKey, refetchRecognizedItemsTrigger, additionalRefreshTriggerSetters }) => {
  const items = [
    {
      key: '1',
      label: 'Table',
      children: <KindredDropsListTable externalRefreshTableKey={externalRefreshTableKey} refetchRecognizedItemsTrigger={refetchRecognizedItemsTrigger} additionalRefreshTriggerSetters={additionalRefreshTriggerSetters}/>
    },
    {
      key: '2',
      label: 'Graph',
      disabled: true
    }
  ]

  return <Card title="Kindred Loot" style={{ overflow: 'none', marginLeft: '16px', marginRight: '16px', marginTop: '16px', marginBottom: '16px' }}><Tabs defaultActiveKey="1" items={items}/></Card>
}

export default KindredDropsListCard
