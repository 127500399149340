import React, { useState, useEffect } from 'react'
import BasicCrudTable from './BasicCrudTable'
import { Input, Select, Tag } from 'antd'
import { type LogInstance } from '../../DashboardUtils'
import { selectAllFrom } from '../../supabaseClient'

interface AntdColor {
  name: string
}

const KindredConfigPaneRecognizedItems: React.FunctionComponent<{
  additionalDispatchersToAnnounce: Array<React.Dispatch<React.SetStateAction<number>>>
  dashboardMessage: LogInstance
}> = ({ additionalDispatchersToAnnounce, dashboardMessage }) => {
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      const { data, error } = await selectAllFrom<AntdColor>('antd_color')
      if (error != null) console.error(error.message)
      else if (data == null) console.error('data was null for antd_color')
      else {
        setOptions(data.map(
          (antdColor: AntdColor) => { return { value: antdColor.name, label: antdColor.name, key: antdColor.name } }
        ))
      }
    }

    fetchOptions().catch(console.error)
  }, [])

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'item_name',
      key: 'itemName',
      input: <Input/>
    },
    {
      title: 'Color',
      dataIndex: 'color_name',
      key: 'colorName',
      input: <Select options={options}/>,
      render: (_: any, record: RecognizedItemResponse) => {
        const colorName = record.color_name
        return <Tag color={colorName}>{colorName}</Tag>
      }
    }
  ]

  return (<>
    <BasicCrudTable
      tableName="kindred_recognized_item"
      elementName="recognized item"
      propColumns={columns}
      deleteKey={'item_name'}
      additionalDispatchersToAnnounce={additionalDispatchersToAnnounce}
      dashboardMessage={dashboardMessage}/>
    </>
  )
}

export default KindredConfigPaneRecognizedItems
