import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import React from 'react'
import { Card, Col, Descriptions, Divider, Progress, type ProgressProps, Row, Space, Typography } from 'antd'
import getPlatDisplayDetails from '../KindredUtils'

const upArrow = <ArrowUpOutlined style={{ color: 'green' }}/>
const downArrow = <ArrowDownOutlined style={{ color: 'red' }}/>

interface PlatStat {
  key: number
  label: string
  statDataKey: keyof KindredSummary
  downArrowBound?: number
  upArrowBound?: number
}

const success: { platCapPercentage: number, status: ProgressProps['status'] } = {
  platCapPercentage: 100,
  status: 'success'
}

const platStatDescriptions: PlatStat[] = [
  {
    key: 1,
    label: 'Hourly',
    statDataKey: 'pastHour',
    downArrowBound: 15000,
    upArrowBound: 28000
  },
  {
    key: 2,
    label: 'Daily',
    statDataKey: 'past24Hours',
    downArrowBound: 240000,
    upArrowBound: 300000
  },
  {
    key: 3,
    label: 'All time',
    statDataKey: 'allTime'
  }
]

const calculatePlatCapPercentage = (recentMax: number, locationName: string): {
  platCapPercentage: number
  status: ProgressProps['status']
} => {
  let potentialMax
  if (locationName === 'Kindred Wilds') {
    if ([59, 599, 1199].includes(recentMax)) {
      return success
    } else {
      potentialMax = 1199
    }
  } else if (locationName === 'Miasmal Lake') {
    if ([599, 899, 1199].includes(recentMax)) {
      return success
    } else {
      potentialMax = 1199
    }
  } else {
    return { platCapPercentage: 100, status: 'active' }
  }

  const platCapPercentage = Math.min(100, 100 * recentMax / potentialMax)
  let status: ProgressProps['status']
  if (platCapPercentage > 90) {
    status = 'success'
  } else if (platCapPercentage > 10) {
    status = 'normal'
  } else {
    status = 'exception'
  }

  return { platCapPercentage, status }
}

export const PlatSummary: React.FunctionComponent<{ statData: KindredSummary }> = ({ statData }) => {
  const getArrowByRate = (rate: number, lowBound: number, highBound: number): React.JSX.Element | null => {
    if (rate <= lowBound) return <><Divider type="vertical"/>{downArrow}</>
    if (rate >= highBound) return <><Divider type="vertical"/>{upArrow}</>
    return null
  }

  const platStatToDescriptionItem = (platStat: PlatStat): React.JSX.Element => {
    let arrowOptional: React.JSX.Element | null = null
    const rate = statData[platStat.statDataKey] as number
    if (platStat.downArrowBound !== undefined && platStat.upArrowBound !== undefined) {
      arrowOptional = getArrowByRate(rate, platStat.downArrowBound, platStat.upArrowBound)
    }

    const { valueToDisplay, decimals, suffix } = getPlatDisplayDetails(rate)

    return <Descriptions.Item
      key={platStat.key}
      label={platStat.label}
      contentStyle={{ wordBreak: 'normal' }}>
      {valueToDisplay?.toFixed(decimals)}{suffix}p{arrowOptional}
    </Descriptions.Item>
  }

  const { platCapPercentage, status } = calculatePlatCapPercentage(statData.recentMax, statData.locationName)
  const strokeColor = status === 'active' ? 'orange' : undefined

  let progressFormat
  if (status === 'active') {
    progressFormat = () => '?'
  } else if (statData.recentMax === 1) {
    progressFormat = undefined
  } else {
    progressFormat = () => statData.recentMax
  }

  return <Card title="Platinum stats" bordered={false} headStyle={{ textAlign: 'center' }}
               style={{ flex: 'auto', width: 'min-content', margin: 1 }}>
    <Row style={{ minWidth: 222 }}>
      <Col span={24}>
        <Space direction="horizontal" align="center">
          <Descriptions colon={false} column={1} size="middle" labelStyle={{ fontSize: 16, paddingTop: 12 }}
                        contentStyle={{ fontSize: 16, paddingTop: 12 }}>
            {platStatDescriptions.map((s) => {
              return platStatToDescriptionItem(s)
            })}
          </Descriptions>
          <Space direction="vertical" style={{ textAlign: 'center' }}>
            <Typography.Title
              level={5}
              style={{ whiteSpace: 'nowrap', color: 'rgba(0, 0, 0, 0.45)', fontWeight: 'normal' }}>
              Platinum rate
            </Typography.Title>
            <Progress size={80} type="circle" percent={platCapPercentage} status={status} strokeColor={strokeColor} format={progressFormat} />
          </Space>
        </Space>
      </Col>
    </Row>
  </Card>
}
