import type { MessageInstance } from 'antd/es/message/interface'

type LogLevel = 'error' | 'info' | 'warning'

type LogMethod = (message: string, error?: any) => void

export type LogInstance = {
  [K in LogLevel]: LogMethod
}

export const useDashboardMessage = (messageApi: MessageInstance): LogInstance => {
  const logMethods: Record<LogLevel, LogMethod> = {
    error: (messageOrError: string | Error, error?: any) => {
      if (messageOrError instanceof Error) {
        console.error(messageOrError)
        void messageApi.error({
          key: messageOrError.message,
          content: messageOrError.message
        })
      } else {
        if (error instanceof Error) {
          console.error(messageOrError, error)
        } else {
          console.error(messageOrError)
        }
        void messageApi.error({
          key: messageOrError,
          content: messageOrError
        })
      }
    },
    info: (message: string) => {
      console.info(message)
      void messageApi.info({
        key: message,
        content: message
      })
    },
    warning: (message: string) => {
      console.warn(message)
      void messageApi.warning({
        key: message,
        content: message
      })
    }
  }

  return logMethods as LogInstance
}
