import React, { useEffect, useState } from 'react'
import { Card, Col, Divider, Dropdown, type MenuProps, Row, Statistic } from 'antd'
import CountUp from 'react-countup'
import { type FormatConfig, type Formatter, type valueType } from 'antd/es/statistic/utils'
import getPlatDisplayDetails from '../KindredUtils'
import { supabase } from '../../supabaseClient'

interface StatMenuItem {
  key: string
  label: string
  type?: string
  disabled?: boolean
}

const statOptions: StatMenuItem[] = [
  {
    key: 'platinum',
    label: 'Platinum'
  },
  {
    key: 'capsule',
    label: 'Capsule',
    disabled: true
  },
  {
    key: 'divider1',
    type: 'divider',
    label: 'unknown'
  },
  {
    key: 'combined',
    label: 'Combined',
    disabled: true
  }
]

const statOptionsMenuProps: MenuProps['items'] = [
  ...statOptions
]

const KindredCardPlatinum: React.FunctionComponent<{ refreshTrigger: number }> = ({ refreshTrigger }) => {
  const [statData, setStatData] = useState<Status>()
  const [isLoading, setLoading] = useState(true)
  const [cardTitle, setCardTitle] = useState<string>((statOptionsMenuProps[0] as { label: string })?.label ?? '')
  const [cardKey, setCardKey] = useState<string>(statOptions[0].key)

  const formatter: Formatter = (value: valueType, _?: FormatConfig) => {
    const { valueToDisplay, suffix = '', decimals } = getPlatDisplayDetails(value)
    // Append p (platinum) only if we're hitting the platinum endpoint
    const formattedSuffix = cardKey === 'platinum' ? `${suffix}p` : suffix
    // Red if 0-1, else normal (black)
    const prop = { color: [0, 1].includes(valueToDisplay ?? 0) ? '#cf1322' : undefined }
    return <CountUp end={valueToDisplay ?? 0}
                    separator=","
                    style={prop}
                    suffix={formattedSuffix}
                    decimals={decimals ?? 0}
                    formattingFn={(value: number) => {
                      return `${value}<span style="color: #a7a7a7">${formattedSuffix}</span>`
                    }}/>
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const option = statOptionsMenuProps.find(option => option?.key === key)
    setCardKey(option?.key?.toString() ?? '')
  }

  const processKindredSummaryData = (data: any): void => {
    if (data.data !== undefined) {
      setStatData(data.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    void supabase
      .rpc('kindred_summary', { hourly: true, daily: true, alltime: true, recenttimeframe: 15 })
      .then(processKindredSummaryData)
  }, [cardKey, refreshTrigger])

  useEffect(() => {
    const option = statOptions.find(option => option?.key === cardKey)
    setCardTitle(option?.label ?? '[unexpected]')
  }, [cardKey])

  let cardBody

  if (!isLoading) {
    if (statData == null) {
      cardBody = 'No data available'
    } else {
      cardBody = <Row>
        <Col span={6}>
          <Statistic title="Hourly" value={statData.pastHour} formatter={formatter}/>
        </Col>
        <Col span={6}>
          <Statistic title="Daily" value={statData.past24Hours} formatter={formatter}/>
        </Col>
        <Col span={6}>
          <Statistic title="All time" value={statData.allTime} formatter={formatter}/>
        </Col>
        <Divider type="vertical" style={{ height: '80px' }}/>
        <Col span={4}>
          <Statistic title="Recent max" value={statData.recentMax} formatter={formatter}
                     style={{ whiteSpace: 'nowrap' }}/>
        </Col>
      </Row>
    }
  }

  const title = (
    <>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Dropdown
            menu={{
              items: statOptionsMenuProps,
              selectable: true,
              defaultSelectedKeys: ['1'],
              onClick
            }}
          >
            <a onClick={(e) => {
              e.preventDefault()
            }} style={{ textDecoration: 'none', fontSize: '16px' }}>
              {cardTitle}
            </a>
          </Dropdown>
        </Col>
        <Col span={20} style={{ fontSize: '16px' }}><Divider type="vertical"/>earnings</Col>
      </Row>
    </>
  )

  return (
    <>
      <Card title={title} loading={isLoading} style={{
        minWidth: '380px',
        marginLeft: '16px',
        marginRight: '16px',
        marginTop: '16px',
        marginBottom: '16px'
      }}>
        {cardBody}
      </Card>
    </>
  )
}

export default KindredCardPlatinum
