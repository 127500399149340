import { Card, Tabs, message } from 'antd'
import KindredConfigPaneItemAliases from './KindredConfigPaneItemAliases'
import KindredConfigPaneRecognizedItems from './KindredConfigPaneRecognizedItems'
import React, { useState } from 'react'
import KindredConfigPaneEnemy from './KindredConfigPaneEnemy'
import KindredConfigPaneEnemyAlias from './KindredConfigPaneEnemyAlias'
import { useDashboardMessage } from '../../DashboardUtils'

const KindredDropsConfig: React.FunctionComponent<{ refreshTableKeys: Array<React.Dispatch<React.SetStateAction<number>>>, onRecognizedItemsChangeKey: React.Dispatch<React.SetStateAction<number>> }> = ({ refreshTableKeys, onRecognizedItemsChangeKey }) => {
  const [refreshItemAliasOptions, setRefreshItemAliasOptions] = useState(0)
  const [messageApi, contextHolder] = message.useMessage()
  const dashboardMessage = useDashboardMessage(messageApi)

  const items = [
    {
      key: '1',
      label: 'Recognized items',
      children: <KindredConfigPaneRecognizedItems
        additionalDispatchersToAnnounce={[onRecognizedItemsChangeKey, setRefreshItemAliasOptions]}
        dashboardMessage={dashboardMessage}/>
    },
    {
      key: '2',
      label: 'Item aliases',
      children: <KindredConfigPaneItemAliases
        refreshOptionsTrigger={refreshItemAliasOptions}
        additionalDispatchersToAnnounce={refreshTableKeys}
        dashboardMessage={dashboardMessage}/>
    },
    {
      key: '3',
      label: 'Enemies',
      children: <KindredConfigPaneEnemy dashboardMessage={dashboardMessage}/>
    },
    {
      key: '4',
      label: 'Enemy aliases',
      children: <KindredConfigPaneEnemyAlias dashboardMessage={dashboardMessage}/>
    },
    {
      key: '5',
      label: 'Locations',
      disabled: true
    }
  ]

  return <>{contextHolder}<Card title="Config" style={{ overflow: 'none', marginLeft: '16px', marginRight: '16px', marginTop: '16px', marginBottom: '16px' }}><Tabs defaultActiveKey="1" items={items}/></Card></>
}

export default KindredDropsConfig
