import { ReloadOutlined } from '@ant-design/icons'
import { Badge, Button, Table, Tag, message } from 'antd'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { supabase } from '../../supabaseClient'

interface KindredDropsListProps {
  externalRefreshTableKey: number
  refetchRecognizedItemsTrigger: number
  additionalRefreshTriggerSetters: Array<React.Dispatch<React.SetStateAction<number>>>
}

interface RecognitionProperties {
  color: string
  unrecognized: boolean
}

interface KindredDropResponse {
  enemy: string
  item: string
  count: number
  enemy_recognized: boolean
  key?: string
}

const RefreshButton: React.FunctionComponent<{
  handleRefresh: (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>) | undefined
  loading: boolean
}> = ({ handleRefresh, loading }) => {
  return <Button type="primary" onClick={handleRefresh} loading={loading}
                 style={{ verticalAlign: 'baseline' }}
                 icon={<ReloadOutlined style={{ verticalAlign: 'baseline' }}/>}>
    Refresh
  </Button>
}

const KindredDropsListTable: React.FunctionComponent<KindredDropsListProps> = ({
  externalRefreshTableKey,
  refetchRecognizedItemsTrigger,
  additionalRefreshTriggerSetters
}) => {
  const [kindredDrops, setKindredDrops] = useState<KindredDropResponse[]>([])
  const [recognizedItems, setRecognizedItems] = useState<RecognizedItemResponse[]>([])
  const [messageApi, contextHolder] = message.useMessage()
  const [internalRefreshKey, setInternalRefreshKey] = useState(0)
  const [anyUnrecognized, setAnyUnrecognized] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchKindredDrops().catch(console.error)
    const interval = setInterval(() => {
      fetchKindredDrops().catch(console.error)
    }, 1000 * 60)
    return () => {
      clearInterval(interval)
    }
  }, [externalRefreshTableKey, internalRefreshKey, recognizedItems])

  useEffect(() => {
    fetchRecognizedItems()
  }, [refetchRecognizedItemsTrigger])

  useEffect(() => {
    checkUnrecognizedStatus()
    setLoading(false)
  }, [kindredDrops, recognizedItems])

  const handleRecognizedItemResponse = (data: any): void => {
    if (data == null) {
      console.error('Recognized Item data should not be null')
    } else {
      setRecognizedItems(data.data)
    }
  }

  const fetchRecognizedItems = (): void => {
    void supabase
      .from('kindred_recognized_item')
      .select('*')
      .then(handleRecognizedItemResponse)
  }

  const addKeyAndSetKindredDrops = (data: any): void => {
    const slicedData = data.slice(0, 50)
    const newData = slicedData.slice(0, 50).map((item: any) => ({
      ...item,
      key: item.id
    }))
    setKindredDrops(newData)
  }

  const fetchKindredDrops = async (): Promise<void> => {
    setLoading(true)
    const { data } = await supabase.rpc('kindred_latest')
    addKeyAndSetKindredDrops(data)
  }

  const checkUnrecognizedStatus = (): void => {
    if (kindredDrops.length !== 0) {
      const unrecognizedItems = kindredDrops.filter((drop: any) => {
        const { unrecognized } = getRecognitionDetails(drop.item)
        return unrecognized
      })
      setAnyUnrecognized(unrecognizedItems.length > 0)
    }
  }

  const getRecognitionDetails = (itemName: string): RecognitionProperties => {
    if (recognizedItems == null) return { color: 'default', unrecognized: false }
    const filteredList = recognizedItems.filter(x => x.item_name === itemName)
    if (filteredList != null && filteredList.length !== 0) {
      return {
        color: filteredList[0].color_name,
        unrecognized: false
      }
    }
    return { color: 'default', unrecognized: true }
  }

  function announceCopied (): void {
    void messageApi.info('Copied to clipboard')
  }

  function copyAndAnnounce (value: string): void {
    navigator.clipboard.writeText(value).catch(console.error)
    announceCopied()
  }

  const columns = [
    {
      title: 'Enemy',
      dataIndex: 'enemy',
      key: 'enemy',
      width: '33%',
      render: (value: string, row: { enemy_recognized: boolean }) => {
        const { enemy_recognized: enemyRecognized } = row
        if (enemyRecognized == null) throw new Error('enemyRecognized property not found')
        return <Badge dot={!enemyRecognized}>
          <span
            onClick={() => {
              copyAndAnnounce(value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                copyAndAnnounce(value)
              }
            }}>{value}</span></Badge>
      }
    },
    {
      title: <Badge dot={anyUnrecognized}>Item</Badge>,
      dataIndex: 'item',
      key: 'item',
      width: '33%',
      render: (value: string, row: { count: number }) => {
        const count = row.count
        const itemString = [0, 1].includes(count) && value !== 'Platinum' ? value : `${count} ${value}`
        const { color, unrecognized } = getRecognitionDetails(value)
        const tag = <Tag color={color} onClick={() => {
          navigator.clipboard.writeText(itemString).catch(console.error)
          announceCopied()
        }}>{itemString}</Tag>
        return <Badge dot={unrecognized}>{tag}</Badge>
      }
    },
    {
      title: 'Time',
      dataIndex: 'drop_date',
      key: 'dropDate',
      width: '33%',
      render: (value: any, _: any) => {
        return moment(new Date(value)).fromNow()
      }
    }
  ]

  const handleRefresh = (): void => {
    setAnyUnrecognized(false)
    setInternalRefreshKey((prevKey) => prevKey + 1)
    additionalRefreshTriggerSetters.forEach((setter) => {
      setter(pastVal => pastVal + 1)
    })
  }
  return (
    <>
      {contextHolder}
      <Table
        dataSource={kindredDrops}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ y: 'calc(100vh - 672px)', x: 300 }}
        footer={() => <RefreshButton handleRefresh={handleRefresh} loading={loading}/>}/> { /* NOSONAR */}
    </>
  )
}

export default KindredDropsListTable
