import React, { useEffect, useState } from 'react'
import BasicCrudTable from './BasicCrudTable'
import { Input, Select } from 'antd'
import { type LogInstance } from '../../DashboardUtils'
import { selectAllFrom } from '../../supabaseClient'

interface KindredEnemyResponse {
  enemy_name: string
}

interface KindredEnemyAlias {
  enemy_uncleansed_name: string
  enemy_name: string
}

const KindredConfigPaneEnemyAlias: React.FunctionComponent<{ dashboardMessage: LogInstance }> = ({ dashboardMessage }) => {
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      const { data, error } = await selectAllFrom<KindredEnemyResponse>('kindred_enemy')
      if (error != null) console.error(error.message)
      else if (data == null) console.error('data was null')
      else {
        setOptions(data.map(
          (enemy: KindredEnemyResponse) => { return { value: enemy.enemy_name, label: enemy.enemy_name } }
        ))
      }
    }

    fetchOptions().catch(console.error)
  }, [])

  const columns: Array<BasicCrudColumn<KindredEnemyAlias>> = [
    {
      title: 'Uncleansed enemy name',
      dataIndex: 'enemy_uncleansed_name',
      key: 'enemyUncleansedName',
      input: <Input/>
    },
    {
      title: 'Enemy name',
      dataIndex: 'enemy_name',
      key: 'enemyName',
      input: <Select options={options}/>
    }
  ]

  return (
    <BasicCrudTable
      tableName="kindred_enemy_alias"
      elementName="enemy alias"
      propColumns={columns}
      deleteKey={'enemy_uncleansed_name'}
      additionalDispatchersToAnnounce={[]}
      dashboardMessage={dashboardMessage}/>
  )
}

export default KindredConfigPaneEnemyAlias
