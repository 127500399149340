import { type valueType } from 'antd/es/statistic/utils'

interface PlatDisplayDetails {
  suffix: string
  decimals: number
  valueToDisplay?: number
}

const platDisplayDetails: PlatDisplayDetails[] = [
  { suffix: '', decimals: 0 },
  { suffix: 'k', decimals: 0 },
  { suffix: 'm', decimals: 1 },
  { suffix: 'b', decimals: 2 }
]

const getSuffixIndex = (value: number): number => {
  if (value === 0) return 0
  return Math.floor(Math.log(value) / Math.log(1000))
}

const getPlatDisplayDetails = (value: valueType): PlatDisplayDetails => {
  const numValue = Number(value)
  const suffixIndex = getSuffixIndex(numValue)
  const valueToDisplay = numValue / Math.pow(1000, suffixIndex)
  return { ...platDisplayDetails[suffixIndex], valueToDisplay }
}

export default getPlatDisplayDetails
