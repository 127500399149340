import React, { useState } from 'react'
import { Col, Row } from 'antd'
import KindredCardPlatinum from '../Cards/KindredCardPlatinum'
import KindredCardInfo from '../Cards/KindredCardInfo'
import KindredDropsConfig from '../Others/KindredDropsConfig'
import KindredDropsListCard from '../Cards/KindredDropsListCard'

const KindredDashboard: React.FunctionComponent = () => {
  const [externalRefreshTableKey, setRefreshDropList] = useState(0)
  const [refetchRecognizedItemsTrigger, setExternalRefreshRecognizedItemsKey] = useState(0)
  const [lootStatRefreshTrigger, setLootStatRefreshTrigger] = useState(0)

  return (
    <>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <KindredCardPlatinum refreshTrigger={lootStatRefreshTrigger}/>
          <KindredCardInfo/>
        </Col>

        <Col span={12}>
          <KindredDropsListCard externalRefreshTableKey={externalRefreshTableKey}
                                refetchRecognizedItemsTrigger={refetchRecognizedItemsTrigger}
                                additionalRefreshTriggerSetters={[setLootStatRefreshTrigger]}/>
        </Col>
        <Col span={12}>
          <KindredDropsConfig refreshTableKeys={[setRefreshDropList]}
                              onRecognizedItemsChangeKey={setExternalRefreshRecognizedItemsKey}/>
        </Col>
      </Row>
    </>
  )
}

export default KindredDashboard
