import { Form } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import { type LogInstance } from '../../DashboardUtils'

interface BasicCrudAddFormProps {
  form: any
  itemAddedHandler: any
  tableName: string
  columns: any[]
  dashboardMessage: LogInstance
}

const BasicCrudAddForm: React.FunctionComponent<BasicCrudAddFormProps> = ({
  form,
  itemAddedHandler: _successfulSubmissionHandler,
  tableName: _tableName,
  columns,
  dashboardMessage: _dashboardMessage
}) => {
  // const handleSubmit = async (values: any): Promise<void> => {
  //   try {
  //     const response = await fetch(tableName, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(values)
  //     })

  //     if (response.ok) {
  //       console.log('Entity added successfully')
  //       // Announce that an item was added
  //       successfulSubmissionHandler()
  //     } else {
  //       dashboardMessage.error('Failed to add entity')
  //     }
  //   } catch (error) {
  //     dashboardMessage.error('Failed to add entity', error)
  //   } finally {
  //     form.resetFields()
  //   }
  // }

  return (
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} form={form}>
      {/* <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={handleSubmit} form={form}></Form> */}
        {columns.map((column) => <FormItem key={column.key} label={column.title} name={column.key}>{column.input}</FormItem>)}
      </Form>
  )
}

export default BasicCrudAddForm
