import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import SummaryAdditionalInfo from '../Others/SummaryAdditionalInfo'
import { supabase } from '../../supabaseClient'
import { PlatSummary } from './PlatSummary'

const cardSummaryTemplateStyle = {
  minWidth: '0px',
  minHeight: '287.422px',
  marginLeft: '16px',
  marginRight: '16px',
  marginTop: '16px',
  marginBottom: '16px',
  maxWidth: 700
}

const KindredCardSummaryTemplate: React.FunctionComponent<{
  loading?: boolean
  kindredSummaryCardContents?: React.JSX.Element
  padding: number
}> = ({ loading, kindredSummaryCardContents, padding }) => {
  return <Card
    title={'Lake Kindred'}
    loading={loading}
    style={cardSummaryTemplateStyle}
    bodyStyle={{
      padding,
      flexFlow: 'wrap',
      display: 'flex'
    }}>
    {kindredSummaryCardContents}
  </Card>
}

const isValidData = (data: any): data is KindredSummary => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return data &&
    typeof data.latestEvent === 'string' &&
    typeof data.allTime === 'number' &&
    typeof data.past24Hours === 'number' &&
    typeof data.pastHour === 'number' &&
    typeof data.recentMax === 'number'
}

const FETCH_INTERVAL = 1000 * 30

const KindredCardSummary: React.FunctionComponent = () => {
  const [statData, setStatData] = useState<KindredSummary | null>(null)
  const [kindredCardLoading, setKindredCardLoading] = useState<boolean>(true)

  const processKindredSummaryData = (data: any): void => {
    const summaryData = data.data as KindredSummary
    if (isValidData(summaryData)) {
      setStatData(summaryData)
      setKindredCardLoading(false)
    } else {
      console.error('Invalid data received from the Summary endpoint')
    }
  }

  const fetchData = (): void => {
    void supabase
      .rpc('kindred_summary', { hourly: true, daily: true, alltime: true, recenttimeframe: 15 })
      .then(processKindredSummaryData)
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData()
    }, FETCH_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  let padding = 0
  let kindredSummaryCardContents
  if (statData == null || statData.pastHour == null) {
    padding = 16
  } else {
    kindredSummaryCardContents = <>
      <PlatSummary statData={statData}/>
      <SummaryAdditionalInfo lastBattle={statData.latestEvent} kindredLocation={statData.locationName}/>
    </>
  }

  return <KindredCardSummaryTemplate
    loading={kindredCardLoading}
    padding={padding}
    kindredSummaryCardContents={kindredSummaryCardContents}/>
}

export default KindredCardSummary
